import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import {
	GridContainer,
	LinkedSingleSelect,
	LinkedTextInput,
	LinkedZIPCodeInput,
} from '@sporkbytes/material-ui-kit-react';

import ClientSelect from './ClientSelect';
import ClientLocationSelect from './ClientLocationSelect';
import onNext from './onNext';
import validationSchema from './validationSchema';

const OrgInfo = ({ dispatch, state, values }) => {
	const { contactFound, servicedStates } = state;
	const data = useStaticQuery(graphql`
		query GetOrgInfo {
			allSporkLocation {
				nodes {
					id
					name
				}
			}
		}
	`);

	return (
		<>
			{data.allSporkLocation.nodes.length > 1 ? (
				<GridContainer columns={2}>
					<LinkedSingleSelect
						label="Spork Bytes Location"
						name="SporkLocationId"
						data-testid="SporkLocationId"
						emptyOptionLabel="None"
						options={data.allSporkLocation.nodes}
						getOptionKey={({ id }) => id}
						getOptionLabel={({ name }) => name}
						getOptionValue={({ id }) => id}
					/>
				</GridContainer>
			) : null}
			<Grid container spacing={1}>
				{contactFound && (
					<Grid item xs={12} sm={6}>
						<ClientSelect dispatch={dispatch} state={state} />
					</Grid>
				)}
				{(!contactFound || values.ClientId === 'Other') && (
					<Grid item xs={12} sm={6}>
						<LinkedTextInput
							name="clientName"
							label="Organization Name"
							autoFocus
						/>
					</Grid>
				)}
			</Grid>
			<Grid container spacing={2}>
				{contactFound &&
					values.ClientId &&
					values.ClientId !== 'Other' && (
						<Grid item xs={12}>
							<ClientLocationSelect
								dispatch={dispatch}
								state={state}
							/>
						</Grid>
					)}
				{(!contactFound ||
					values.ClientId === 'Other' ||
					values.ClientLocationId === 'Other') && (
					<>
						<Grid item xs={12} sm={6}>
							<LinkedTextInput name="streetAddress" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<LinkedTextInput name="streetAddress2" />
						</Grid>
						<Grid item xs={12} sm={4}>
							<LinkedTextInput name="city" />
						</Grid>
						<Grid item xs={12} sm={4}>
							<LinkedSingleSelect
								name="state"
								data-testid="state"
								options={servicedStates}
								getOptionKey={({ id }) => id}
								getOptionLabel={({ name }) => name}
								getOptionValue={({ abbreviation }) =>
									abbreviation
								}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<LinkedZIPCodeInput
								name="zipCode"
								label="ZIP Code"
							/>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

OrgInfo.analyticsLabel = 'Organization Info';
OrgInfo.label = (
	<span role="img" aria-label="Office building emoji">
		🏢 Where to?
	</span>
);

OrgInfo.initialValues = {
	ClientId: '',
	clientName: '',
	ClientLocationId: '',
	streetAddress: '',
	streetAddress2: '',
	city: '',
	state: '',
	zipCode: '',
};

OrgInfo.validationSchema = validationSchema;

OrgInfo.onNext = onNext;

OrgInfo.propTypes = {
	setFieldValue: PropTypes.func.isRequired,
	state: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
};

export default OrgInfo;

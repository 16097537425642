import React, { useEffect } from 'react';
import { connect } from 'formik';
import { Alert, LinkedSingleSelect } from '@sporkbytes/material-ui-kit-react';

const ClientLocationSelect = ({ dispatch, formik: { values }, state }) => {
	const { contactData } = state;
	const options = [
		...contactData.Clients.filter(
			({ id }) => id === values.ClientId
		).flatMap(({ ClientLocations }) =>
			ClientLocations.map(({ id, name, fullAddress }) => ({
				key: id,
				label: `${!!name ? name + ' - ' : ''}${fullAddress}`,
				value: id,
			}))
		),
		{
			key: 'other',
			label: 'Other',
			value: 'Other',
		},
	];

	// Reset Client Location metadata when changing ClientLocationId
	useEffect(() => {
		const { ClientLocationId } = values;

		if (ClientLocationId !== state.selectedClientLocationId) {
			dispatch({
				type: 'setStateValue',
				payload: {
					key: 'selectedClientLocationId',
					value: ClientLocationId,
				},
			});
		}
	}, [values.ClientLocationId]);

	return Array.isArray(contactData.Clients) ? (
		<LinkedSingleSelect
			name="ClientLocationId"
			label="Where does this meal need to be delivered?"
			data-testid="ClientLocationId"
			options={options}
			getOptionKey={({ key }) => key}
			getOptionLabel={({ label }) => label}
			getOptionValue={({ value }) => value}
		/>
	) : (
		<Alert type="error">
			Data is not in the right format. Please refresh the page and try
			again.
		</Alert>
	);
};

export default connect(ClientLocationSelect);

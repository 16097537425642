import * as Yup from 'yup';

import { addressComponentRequired } from './lib';

export default state =>
	Yup.object().shape({
		ClientId: state.contactFound
			? Yup.string().required('Please select a client.')
			: Yup.string(),
		clientName: Yup.string().when('ClientId', {
			is: value => value === 'Other' || !state.contactFound,
			then: Yup.string().required('Please enter your business name.'),
			otherwise: Yup.string(),
		}),
		ClientLocationId: Yup.string().when('ClientId', {
			is: value =>
				value !== '' && value !== 'Other' && state.contactFound,
			then: Yup.string().required('Please select a location.'),
			otherwise: Yup.string(),
		}),
		SporkLocationId: Yup.string().required(
			'Please select a Spork Bytes Location.'
		),
		streetAddress: Yup.string().when(['ClientId', 'ClientLocationId'], {
			is: addressComponentRequired(state),
			then: Yup.string().required('Please enter an address.'),
			otherwise: Yup.string(),
		}),
		streetAddress2: Yup.string(),
		city: Yup.string().when(['ClientId', 'ClientLocationId'], {
			is: addressComponentRequired(state),
			then: Yup.string().required('Please enter a city.'),
			otherwise: Yup.string(),
		}),
		state: Yup.string().when(['ClientId', 'ClientLocationId'], {
			is: addressComponentRequired(state),
			then: Yup.string().required('Please select a state.'),
			otherwise: Yup.string(),
		}),
		zipCode: Yup.string().when(['ClientId', 'ClientLocationId'], {
			is: addressComponentRequired(state),
			then: Yup.string().required('Please enter a ZIP code.'),
			otherwise: Yup.string(),
		}),
	});

import React from 'react';
import { graphql } from 'gatsby';
import qs from 'querystringify';

import FormContainer from 'components/FormContainer';
import PageWrapper from 'components/PageWrapper';
import StateWrapper from 'components/ClientOrderRequestForm/StateWrapper';

import Hero from 'storyblok/components/Hero';

const SelectedPartnerContext = React.createContext();

const GetStarted = ({ data, location: { search } }) => {
	return (
		<SelectedPartnerContext.Provider value={qs.parse(search).restaurant}>
			<PageWrapper
				title="Get Started"
				postContent={
					<>
						<Hero
							blok={{
								backgroundImage:
									data.headerImage.childImageSharp.fluid,
								headline: 'Get Started',
								height: 'short',
								subHeadline: `Fill out this form and we'll get in touch shortly!`,
							}}
						/>
						<FormContainer>
							<StateWrapper />
						</FormContainer>
					</>
				}
				displayNewsletterSignup={false}
			/>
		</SelectedPartnerContext.Provider>
	);
};

export const query = graphql`
	query GetHeaderImageForGetStarted {
		headerImage: file(relativePath: { eq: "get-started-hero.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1500) {
					...GatsbyImageSharpFluid_tracedSVG
				}
				fixed(height: 630, width: 1200) {
					src
				}
			}
		}
	}
`;

export default GetStarted;
export { SelectedPartnerContext };

export const initialState = {
	activeStep: 0,
	completedSteps: {},
	contactData: undefined,
	contactFound: false,
	contactHasOrdered: false,
	contactSearchedFor: false,
	emailAddress: '',
	formSubmitted: false,
	initialPartnerSelectionSet: false,
	isCodeValid: false,
	partnerList: undefined,
	selectedClientId: '',
	selectedClientLocationId: '',
};

export const reducer = (state, action) => {
	switch (action.type) {
		case 'completeStep': {
			return {
				...state,
				completedSteps: {
					...state.completedSteps,
					[action.payload.name]: true,
				},
			};
		}
		case 'resetState': {
			return {
				...initialState,
				...action.payload,
			};
		}
		case 'setStateValue':
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		default:
			return state;
	}
};

import React from 'react';
import {
	LinkedPhoneNumberInput,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

const ContactInfo = () => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={4}>
				<LinkedTextInput name="firstName" autoFocus />
			</Grid>
			<Grid item xs={12} sm={4}>
				<LinkedTextInput name="lastName" />
			</Grid>
			<Grid item xs={12} sm={4}>
				<LinkedPhoneNumberInput name="phoneNumber" />
			</Grid>
		</Grid>
	);
};

ContactInfo.analyticsLabel = 'Contact Info';
ContactInfo.label = (
	<span role="img" aria-label="Smiley face emoji">
		😊 Tell us a little bit about yourself...
	</span>
);

ContactInfo.initialValues = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
};

ContactInfo.validationSchema = state =>
	Yup.object().shape({
		firstName: Yup.string().required('Please enter your first name.'),
		lastName: Yup.string().required('Please enter your last name.'),
		phoneNumber: Yup.string().required(
			'Please enter a phone number (we will only call you in case of issues on the day of delivery).'
		),
	});

export default ContactInfo;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
	Alert,
	Form,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { validateCode } from '@sporkbytes/api-client/PublicContacts';

import FormHelperText from '../FormHelperText';
import withRecaptcha from '../withRecaptcha';

import axios from '../../services/axios';
import { sendEvent } from '../../services/analytics';

const validationSchema = Yup.object().shape({
	validationCode: Yup.string()
		.length(6, 'Your code is not the right length.')
		.required(
			'Please enter the validation code sent to your email address.'
		),
});

const CodeValidationForm = ({
	dispatch,
	getRecaptchaToken,
	state: { emailAddress },
}) => {
	const [error, setError] = useState();

	const onSubmit = async ({ validationCode }, { setSubmitting }) => {
		try {
			const recaptchaResponse = await getRecaptchaToken();
			const { contactData, isCodeValid } = await validateCode(axios, {
				validationCode,
				emailAddress,
				recaptchaResponse,
			});

			dispatch({
				type: 'setStateValue',
				payload: {
					key: 'contactData',
					value: contactData,
				},
			});
			dispatch({
				type: 'setStateValue',
				payload: {
					key: 'isCodeValid',
					value: isCodeValid,
				},
			});
			sendEvent({
				action: 'Enter Validation Code',
				label: isCodeValid ? 'Valid' : 'Invalid',
			});

			if (isCodeValid) {
				setError();
			} else {
				setError(
					'The code you entered was incorrect or has expired. Please try again.'
				);
			}
		} catch {
			setError(
				'There was an error processing your request. Please try again later.'
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			onCancel={null}
			initialValues={{
				validationCode: '',
			}}
			validationSchema={validationSchema}
			data-testid="codeValidationForm"
			submitButtonContent="Submit Code"
		>
			{() => (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormHelperText>
							It looks like we found you in our system! Please
							copy and paste the validation code we sent to your
							email so we can pull your information.
						</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={6}>
						<LinkedTextInput
							name="validationCode"
							placeholder="123456"
							inputProps={{
								inputMode: 'numeric',
							}}
							autoFocus
							autoComplete="off"
						/>
						{error && <Alert type="error">{error}</Alert>}
					</Grid>
				</Grid>
			)}
		</Form>
	);
};

CodeValidationForm.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRecaptcha(CodeValidationForm);

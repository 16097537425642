import { zonedTimeToUtc } from 'date-fns-tz';
import {
	addDays,
	setHours,
	setMilliseconds,
	setMinutes,
	setSeconds,
} from 'date-fns';

function getDefaultDeliveryDate(timezone) {
	let defaultDeliveryDate = setHours(new Date(), 12);
	defaultDeliveryDate = setMinutes(defaultDeliveryDate, 0);
	defaultDeliveryDate = setSeconds(defaultDeliveryDate, 0);
	defaultDeliveryDate = setMilliseconds(defaultDeliveryDate, 0);
	defaultDeliveryDate = addDays(defaultDeliveryDate, 1);

	return zonedTimeToUtc(defaultDeliveryDate, timezone);
}

export { getDefaultDeliveryDate };

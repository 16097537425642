import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import ClientInfoRequestForm from './ClientInfoRequestForm';
import FindContactForm from './FindContactForm';

import { sendEvent } from '../../services/analytics';

const PANEL_HEADINGS = {
	ready: 'Request a Quote / Re-order',
	notReady: "I'm just looking for information",
};

const EntryPoint = ({ dispatch }) => {
	const [expanded, setExpanded] = useState('ready');

	const handleChange = panel => (_, isExpanded) => {
		const panelHeading = PANEL_HEADINGS[panel];

		setExpanded(isExpanded ? panel : false);

		sendEvent({
			action: isExpanded ? 'Expand Form' : 'Collapse Form',
			label: panelHeading,
		});
	};

	return (
		<Grid container component="section" spacing={4}>
			<Grid item xs={12}>
				<ExpansionPanel
					expanded={expanded === 'ready'}
					onChange={handleChange('ready')}
				>
					<ExpansionPanelSummary
						expandIcon={<ExpandMore />}
						aria-controls="ready-content"
						id="ready-header"
					>
						<Typography variant="h4" component="h3">
							{PANEL_HEADINGS.ready}
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Box>
							<FindContactForm dispatch={dispatch} />
						</Box>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel
					expanded={expanded === 'notReady'}
					onChange={handleChange('notReady')}
				>
					<ExpansionPanelSummary
						expandIcon={<ExpandMore />}
						aria-controls="not-ready-content"
						id="not-ready-header"
					>
						<Typography variant="h4" component="h3">
							{PANEL_HEADINGS.notReady}
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Box>
							<ClientInfoRequestForm dispatch={dispatch} />
						</Box>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Grid>
		</Grid>
	);
};

EntryPoint.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default EntryPoint;

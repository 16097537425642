function getTimezone(state) {
	const timezones = {
		OR: 'America/Los_Angeles',
		WA: 'America/Los_Angeles',
	};

	return timezones[state];
}

export { getTimezone };

import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

import { addressComponentRequired } from './lib';
import { getTimezone } from '../../../../services/maps';
import { defaultDietaryRestrictions } from '../MealInfo';

export default async (values, formikBag, state) => {
	const { setFieldValue, setValues } = formikBag;
	const {
		timezone: previousTimezone,
		deliveryDate: previousDeliveryDate,
		state: locationState,
		ClientId,
		ClientLocationId,
	} = values;

	let newTimezone;
	let headCount = '';
	let deliveryInstructions = '';
	let setupInstructions = '';
	let dietaryRestrictions = defaultDietaryRestrictions;
	let budgetType = '';
	let budget = '';

	if (addressComponentRequired(state)(ClientId, ClientLocationId)) {
		newTimezone = getTimezone(locationState);
	} else {
		const clientLocation = state.contactData.Clients.find(
			({ id }) => id === ClientId
		).ClientLocations.find(({ id }) => id === ClientLocationId);

		const { budgetPerPerson, totalBudget } = clientLocation;

		newTimezone = clientLocation.timezone;
		headCount = clientLocation.headCount || '';
		deliveryInstructions = clientLocation.deliveryInstructions || '';
		setupInstructions = clientLocation.setupInstructions || '';
		dietaryRestrictions = clientLocation.dietaryRestrictions || '';

		if (budgetPerPerson) {
			budgetType = 'perPerson';
			budget = budgetPerPerson;
		} else if (totalBudget) {
			budgetType = 'total';
			budget = totalBudget;
		}
	}

	setValues({
		...values,
		headCount,
		deliveryInstructions,
		setupInstructions,
		dietaryRestrictions,
		budget,
		budgetType,
		timezone: newTimezone,
	});

	if (previousDeliveryDate && newTimezone !== previousTimezone) {
		let newDeliveryDate = utcToZonedTime(
			previousDeliveryDate,
			previousTimezone
		);
		newDeliveryDate = zonedTimeToUtc(newDeliveryDate, newTimezone);

		setFieldValue('deliveryDate', newDeliveryDate);
	}
};

import React, { useEffect } from 'react';
import { connect } from 'formik';
import { Alert, LinkedSingleSelect } from '@sporkbytes/material-ui-kit-react';

const ClientSelect = ({
	dispatch,
	formik: { setFieldValue, values },
	state,
}) => {
	const { contactData } = state;
	const options = [
		...contactData.Clients.map(({ id, name }) => ({
			key: id,
			label: name,
			value: id,
		})),
		{
			key: 'other',
			label: 'Other',
			value: 'Other',
		},
	];

	// Reset Client Name and selected ClientLocationId when changing ClientId
	useEffect(() => {
		const { ClientId } = values;

		if (ClientId !== state.selectedClientId) {
			dispatch({
				type: 'setStateValue',
				payload: {
					key: 'selectedClientId',
					value: ClientId,
				},
			});

			// reset the selected ClientLocation
			setFieldValue('ClientLocationId', '');
			dispatch({
				type: 'setStateValue',
				payload: {
					key: 'selectedClientLocationId',
					value: '',
				},
			});
		}
	}, [values.ClientId]);

	return Array.isArray(contactData.Clients) ? (
		<LinkedSingleSelect
			name="ClientId"
			label="Who is this meal for?"
			data-testid="ClientId"
			options={options}
			getOptionKey={({ key }) => key}
			getOptionLabel={({ label }) => label}
			getOptionValue={({ value }) => value}
		/>
	) : (
		<Alert type="error">
			Data is not in the right format. Please refresh the page and try
			again.
		</Alert>
	);
};

export default connect(ClientSelect);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
	Alert,
	Form,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { exists } from '@sporkbytes/api-client/PublicContacts';

import FormHelperText from '../FormHelperText';
import withRecaptcha from '../withRecaptcha';

import axios from '../../services/axios';
import { sendEvent } from '../../services/analytics';

const validationSchema = Yup.object().shape({
	emailAddress: Yup.string()
		.email('Please enter a valid email address.')
		.required('Please enter your email address.'),
});

const FindContactForm = ({ dispatch, getRecaptchaToken }) => {
	const [error, setError] = useState();

	const onSubmit = async ({ emailAddress }, { setSubmitting }) => {
		try {
			const recaptchaResponse = await getRecaptchaToken();
			const { contactFound, contactHasOrdered } = await exists(axios, {
				emailAddress,
				recaptchaResponse,
			});

			setError(undefined);

			dispatch({
				type: 'setStateValue',
				payload: { key: 'emailAddress', value: emailAddress },
			});
			dispatch({
				type: 'setStateValue',
				payload: { key: 'contactFound', value: contactFound },
			});
			dispatch({
				type: 'setStateValue',
				payload: { key: 'contactHasOrdered', value: contactHasOrdered },
			});
			dispatch({
				type: 'setStateValue',
				payload: { key: 'contactSearchedFor', value: true },
			});
			sendEvent({
				action: 'Enter Email Address',
				label:
					contactFound && contactHasOrdered
						? 'Returning Customer'
						: 'New Customer',
			});
			sendEvent({
				action: 'Complete Step',
				label: 'Enter Email Address',
			});
		} catch {
			setError(
				'There was an error processing your request. Please try again later.'
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{
				emailAddress: '',
			}}
			validationSchema={validationSchema}
			data-testid="findContactForm"
			submitButtonContent="Get Started"
			onCancel={null}
		>
			{() => (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormHelperText>
							Please enter your email address. If you're in our
							system, we'll fill out part of the form for you.
						</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={6}>
						<LinkedTextInput
							name="emailAddress"
							placeholder="jill@example.com"
							type="email"
							autoFocus
						/>
						{error && <Alert type="error">{error}</Alert>}
					</Grid>
				</Grid>
			)}
		</Form>
	);
};

FindContactForm.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRecaptcha(FindContactForm);

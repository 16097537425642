import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	Checkbox,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { Restaurant } from '@material-ui/icons';
import { FixedSizeList as List } from 'react-window';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import ViewMenusButton from '../ViewMenusButton';

import { sendEvent } from '../../services/analytics';

const PartnerList = ({
	filteredPartners,
	listHeight,
	listItemHeight,
	onChange,
	partners,
	selectedPartners,
}) => {
	const [errorMessage, setErrorMessage] = useState();
	const handlePartnerClick = clickedPartner => {
		let newPartners;
		const { name, isFeaturedPartner } = clickedPartner;
		const eventLabel = `${name} - ${
			isFeaturedPartner ? 'Featured' : 'Non-Featured'
		}`;

		if (selectedPartners.includes(clickedPartner)) {
			newPartners = selectedPartners.filter(
				partner => partner !== clickedPartner
			);

			sendEvent({
				action: 'Deselect Partner',
				label: eventLabel,
			});
		} else if (
			selectedPartners.length >= process.env.GATSBY_MAX_SELECTED_PARTNERS
		) {
			setErrorMessage(
				`You may select up to ${process.env.GATSBY_MAX_SELECTED_PARTNERS} restaurants.`
			);

			return;
		} else {
			newPartners = partners.filter(
				partner =>
					selectedPartners.includes(partner) ||
					partner === clickedPartner
			);

			sendEvent({
				action: 'Select Partner',
				label: eventLabel,
			});
		}

		setErrorMessage(null);
		onChange(newPartners);
	};

	const Partner = ({ index, style }) => {
		const partner = filteredPartners[index];

		return (
			<ListItem
				// ContainerProps and ContainerComponent need to be set to these values for ListItem to render ListItemSecondaryAction properly https://github.com/mui-org/material-ui/issues/16026
				ContainerProps={{ style }}
				ContainerComponent="div"
				button
				onClick={() => handlePartnerClick(partner)}
			>
				<ListItemIcon>
					<Checkbox checked={selectedPartners.includes(partner)} />
				</ListItemIcon>
				<ListItemText primary={partner.name}></ListItemText>
				<ListItemSecondaryAction>
					<ViewMenusButton
						partner={partner}
						showGetStartedButton={false}
						showPartnerInDialog={true}
					>
						{onClick => (
							<IconButton onClick={onClick}>
								<Restaurant />
							</IconButton>
						)}
					</ViewMenusButton>
				</ListItemSecondaryAction>
			</ListItem>
		);
	};

	return (
		<>
			<Card>
				{filteredPartners.length > 0 ? (
					<List
						itemCount={filteredPartners.length}
						height={listHeight}
						itemSize={listItemHeight}
						width="100%"
						itemKey={index => filteredPartners[index].id}
					>
						{Partner}
					</List>
				) : (
					<CardContent>
						<Typography variant="body2" align="center">
							No restaurants found. Please try a different search.
						</Typography>
					</CardContent>
				)}
			</Card>
			{errorMessage && <Alert type="error">{errorMessage}</Alert>}
		</>
	);
};

PartnerList.propTypes = {
	filteredPartners: PropTypes.array.isRequired,
	listHeight: PropTypes.number.isRequired,
	listItemHeight: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	partners: PropTypes.array.isRequired,
	selectedPartners: PropTypes.array.isRequired,
};

export default PartnerList;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info as InfoIcon } from '@material-ui/icons';
import { envAgnostic } from '@sporkbytes/config';
import {
	LinkedCurrencyInput,
	LinkedDateTimePicker,
	LinkedRadioGroup,
	LinkedRichTextEditor,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import * as Yup from 'yup';

import { getDefaultDeliveryDate } from '../../../services/date';

const useStyles = makeStyles(theme => ({
	datePickerContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	datePickerTooltip: {
		marginLeft: theme.spacing(1),
	},
}));

const serviceStyles = [
	{
		label: 'Buffet Style',
		value: 'buffet style',
	},
	{
		label: 'Boxed Meals',
		value: 'boxed meals',
	},
	{
		label: 'Other',
		value: 'other',
	},
];

const budgetTypes = [
	{
		label: 'Per Person',
		value: 'perPerson',
	},
	{
		label: 'Total',
		value: 'total',
	},
];

const getOptionKey = ({ value }) => value;
const getOptionLabel = ({ label }) => label;
const getOptionValue = ({ value }) => value;

const MealInfo = ({ values }) => {
	const classes = useStyles();
	const defaultDeliveryDate = getDefaultDeliveryDate(values.timezone);
	const deliveryGracePeriod = `${envAgnostic.deliveryGracePeriod.amount} ${envAgnostic.deliveryGracePeriod.scale}`;

	return (
		<>
			<Grid container spacing={4}>
				<Grid
					item
					xs={12}
					sm={6}
					className={classes.datePickerContainer}
				>
					<LinkedDateTimePicker
						name="deliveryDate"
						label="Date and Time"
						initialFocusedDate={defaultDeliveryDate}
						minDate={new Date()}
						timezone={values.timezone}
						data-testid="deliveryDate"
					/>
					<Tooltip
						title={`On Time Policy: Your meal will be ready to eat between ${deliveryGracePeriod} before and ${deliveryGracePeriod} after the selected time.`}
					>
						<InfoIcon className={classes.datePickerTooltip} />
					</Tooltip>
				</Grid>
				<Grid item xs={12} sm={6}>
					<LinkedTextInput name="headCount" type="number" />
				</Grid>
				<Grid item xs={12}>
					<LinkedRadioGroup
						name="serviceStyle"
						row
						options={serviceStyles}
						getOptionKey={getOptionKey}
						getOptionLabel={getOptionLabel}
						getOptionValue={getOptionValue}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<LinkedCurrencyInput name="budget" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<LinkedRadioGroup
						name="budgetType"
						row
						options={budgetTypes}
						getOptionKey={getOptionKey}
						getOptionLabel={getOptionLabel}
						getOptionValue={getOptionValue}
					/>
				</Grid>
				<Grid item xs={12}>
					<LinkedRichTextEditor
						name="deliveryInstructions"
						placeholder="Example: enter through front door and check in with security guard"
					/>
				</Grid>
				<Grid item xs={12}>
					<LinkedRichTextEditor
						name="setupInstructions"
						placeholder="Example: set up on long buffet table in kitchen area"
					/>
				</Grid>
				<Grid item xs={12}>
					<LinkedRichTextEditor
						name="dietaryRestrictions"
						placeholder="Please list the number of people with each allergy and dietary preference"
					/>
				</Grid>
			</Grid>
		</>
	);
};

export const defaultDietaryRestrictions = `<ul><li>Vegan: 0</li><li>Vegetarian: 0</li><li>Gluten-Free: 0</li><li>Dairy-Free: 0</li><li>Nut-Free: 0</li><li>Soy-Free: 0</li><li>Other:</li></ul>`;

MealInfo.analyticsLabel = 'Meal Info';
MealInfo.label = (
	<span role="img" aria-label="Fork, knife, and plate emoji">
		🍽 Meal Information
	</span>
);

MealInfo.initialValues = {
	deliveryDate: '',
	timezone: '',
	headCount: '',
	serviceStyle: '',
	budget: '',
	budgetType: '',
	deliveryInstructions: '',
	setupInstructions: '',
	dietaryRestrictions: defaultDietaryRestrictions,
};

MealInfo.validationSchema = state =>
	Yup.object().shape({
		deliveryDate: Yup.date().required(
			'Please choose a date and time for your meal.'
		),
		headCount: Yup.number().required('Please enter your head count.'),
		serviceStyle: Yup.string().required('Please select a service style.'),
		budget: Yup.number(),
		budgetType: Yup.string(),
	});

MealInfo.propTypes = {
	isSubmitting: PropTypes.bool.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
};

export default MealInfo;

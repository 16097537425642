import React from 'react';
import { Typography } from '@material-ui/core';

const FormHelperText = ({ children }) => (
	<Typography variant="h5" component="h3" gutterBottom>
		{children}
	</Typography>
);

export default FormHelperText;

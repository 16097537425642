import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ConfettiGenerator from 'confetti-js';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Link from './Link';
import PrimaryButton from './PrimaryButton';

const useStyles = makeStyles(theme => ({
	body: {
		marginBottom: theme.spacing(3),
	},
	celebration: {
		bottom: 0,
		left: 0,
		pointerEvents: 'none',
		position: 'fixed',
		right: 0,
		top: 0,
	},
	heading: {
		margin: theme.spacing(3, 0),
	},
}));

const ThankYou = ({ primaryText, secondaryAction, secondaryText }) => {
	useEffect(() => {
		const confettiSettings = {
			rotate: true,
			target: 'celebration',
			colors: [[66, 158, 145], [241, 101, 71]],
			props: [
				'square',
				'line',
				'triangle',
				{
					type: 'svg',
					src:
						'https://cdn.sporkbytes.com/logos/spork/teal_crossed_sporks.svg',
					size: 20,
					weight: 0.15,
				},
				{
					type: 'svg',
					src:
						'https://cdn.sporkbytes.com/logos/spork/orange_crossed_sporks.svg',
					size: 20,
					weight: 0.15,
				},
			],
		};
		const confetti = new ConfettiGenerator(confettiSettings);
		const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

		if (!isIE11) {
			confetti.render();
		}

		return () => confetti.clear();
	}, []);

	const classes = useStyles();

	return (
		<>
			<Typography variant="h3" component="h1" className={classes.heading}>
				{primaryText}
			</Typography>
			<Typography className={classes.body}>{secondaryText}</Typography>
			<Typography className={classes.body}>
				<PrimaryButton component={Link} to="/">
					Back to Home
				</PrimaryButton>
				{secondaryAction}
			</Typography>
			<canvas id="celebration" className={classes.celebration}></canvas>
		</>
	);
};

ThankYou.propTypes = {
	primaryText: PropTypes.string.isRequired,
	secondaryText: PropTypes.string.isRequired,
};

export default ThankYou;

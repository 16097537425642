import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info as InfoIcon } from '@material-ui/icons';
import {
	LinkedAutocomplete,
	LinkedCheckboxGroup,
	LinkedRadioGroup,
	LinkedRichTextEditor,
} from '@sporkbytes/material-ui-kit-react';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
	infoIcon: {
		fill: 'black',
		marginLeft: theme.spacing(1),
		verticalAlign: 'middle',
	},
	suppliesLabel: {
		verticalAlign: 'middle',
	},
}));

const AddOns = ({ state }) => {
	const classes = useStyles();
	const addOnGroups = useStaticQuery(graphql`
		query GetAddOns {
			allStoryblokEntry(
				filter: { field_component: { eq: "AddOnGroup" } }
				sort: { fields: field_sortOrder_string, order: ASC }
			) {
				nodes {
					uuid
					name
					content
				}
			}
		}
	`);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<LinkedRadioGroup
					name="supplies"
					label={
						<>
							<span className={classes.suppliesLabel}>
								Do you need supplies?
							</span>
							<Tooltip
								title="This includes plates, napkins, cutlery, and serving
					utensils."
							>
								<InfoIcon className={classes.infoIcon} />
							</Tooltip>
						</>
					}
					options={[
						{ label: 'Yes', value: 'yes' },
						{ label: 'No', value: 'no' },
					]}
					getOptionKey={({ value }) => value}
					getOptionLabel={({ label }) => label}
					getOptionValue={({ value }) => value}
					row
				/>
			</Grid>
			{addOnGroups.allStoryblokEntry.nodes.map(addOnGroup => (
				<Grid item xs={12} key={addOnGroup.uuid}>
					<LinkedCheckboxGroup
						name={`addOns[${addOnGroup.name}]`}
						label={addOnGroup.name}
						options={JSON.parse(addOnGroup.content).addOns}
						getOptionKey={({ _uid }) => _uid}
						getOptionLabel={({ name }) => name}
						getOptionValue={({ name }) => name}
						row
					/>
				</Grid>
			))}
			<Grid item xs={12}>
				<LinkedRichTextEditor
					name="comments"
					placeholder="Please list any specifics you would like us to know, for instance if this is a series of meals or if you have any special requirements we haven't yet covered."
				/>
			</Grid>
			{state.contactFound === false ? (
				<Grid item xs={6}>
					<LinkedAutocomplete
						options={[
							'Ad',
							'Referral from a colleague or friend',
							'Web Search',
							'Instagram',
							'Facebook',
							'Twitter',
							'LinkedIn',
							'Blog',
							'Email',
							'Phone Call',
							'Mailer',
							'Event',
							'Technology Association of Oregon',
							'Think NW',
							'Portland Business Alliance',
							'Other',
						]}
						getOptionLabel={option => option}
						name="howDidYouHearAboutUs"
						placeholder="How Did You Hear About Us?"
						showErrorBeforeTouched={true}
					/>
				</Grid>
			) : null}
		</Grid>
	);
};

AddOns.analyticsLabel = 'Add Ons';
AddOns.label = (
	<span role="img" aria-label="Magnifying glass emoji">
		🔍 Is there anything else you need?
	</span>
);

AddOns.initialValues = {
	supplies: 'yes',
	addOns: {},
	comments: '',
};

AddOns.validationSchema = state =>
	Yup.object().shape({
		howDidYouHearAboutUs: state.contactFound
			? Yup.string()
			: Yup.string().required('Please select an option.'),
	});

export default AddOns;

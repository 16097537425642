import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import Fuse from 'fuse.js';

import PartnerList from '../PartnerList';

import { SelectedPartnerContext } from 'pages/get-started';

const PARTNER_LIST_HEIGHT = 350;
const PARTNER_LIST_ITEM_HEIGHT = 70;

const getPartnerNames = partners => partners.map(({ name }) => name).join(', ');

const PartnerSelection = ({ dispatch, setFieldValue, state, values }) => {
	const selectedPartnerSlug = useContext(SelectedPartnerContext);
	const [searchTerm, setSearchTerm] = useState('');
	const partnersAvailableForSporkLocation = state.partnerList.filter(
		({ SporkLocations }) =>
			SporkLocations.some(({ id }) => id === values.SporkLocationId)
	);

	const fuse = new Fuse(partnersAvailableForSporkLocation, {
		threshold: 0.3,
		keys: ['name'],
	});

	useEffect(() => {
		const selectedPartner = partnersAvailableForSporkLocation.find(
			partner => partner.landingPageSlug === selectedPartnerSlug
		);

		if (selectedPartner && !state.initialPartnerSelectionSet) {
			setFieldValue('selectedPartners', [selectedPartner]);
		}

		dispatch({
			type: 'setStateValue',
			payload: {
				key: 'initialPartnerSelectionSet',
				value: true,
			},
		});
	}, [selectedPartnerSlug]);

	return (
		<>
			<Grid
				container
				direction="column"
				alignItems="stretch"
				wrap="nowrap" // This property is needed to keep the partner search input field from changing its size
				spacing={2}
			>
				<Grid item>
					<Alert type="info" style={{ margin: 0 }}>
						Select up to {process.env.GATSBY_MAX_SELECTED_PARTNERS}{' '}
						restaurants or feel free to leave this blank if you
						would prefer that we make suggestions{' '}
						<span role="img" aria-label="Thumbs up emoji">
							👍
						</span>
					</Alert>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						placeholder="Search for restaurants"
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search color="action" />
								</InputAdornment>
							),
							endAdornment: searchTerm !== '' && (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchTerm('')}
										data-testid="clearPartnerSearch"
									>
										<Close color="action" />
									</IconButton>
								</InputAdornment>
							),
							'data-testid': 'partnerSearch',
						}}
						type="search"
						fullWidth
						autoFocus
					/>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						Selected Restaurants:{' '}
						{getPartnerNames(values.selectedPartners) || 'None'}
					</Typography>
				</Grid>
				<Grid item>
					<PartnerList
						partners={partnersAvailableForSporkLocation}
						filteredPartners={
							searchTerm
								? fuse.search(searchTerm)
								: partnersAvailableForSporkLocation
						}
						selectedPartners={values.selectedPartners}
						onChange={selectedPartners => {
							setFieldValue('selectedPartners', selectedPartners);
						}}
						listHeight={PARTNER_LIST_HEIGHT}
						listItemHeight={PARTNER_LIST_ITEM_HEIGHT}
					/>
				</Grid>
			</Grid>
		</>
	);
};

PartnerSelection.analyticsLabel = 'Partner Selection';
PartnerSelection.label = (
	<span role="img" aria-label="Pizza emoji">
		🍕 Restaurant Selection
	</span>
);
PartnerSelection.initialValues = {
	selectedPartners: [],
};

PartnerSelection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	state: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
};

export default PartnerSelection;

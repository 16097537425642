import React, { useEffect, useReducer } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles } from '@material-ui/core';

import ClientInfoRequestForm from './ClientInfoRequestForm';
import ClientOrderRequestForm from './ClientOrderRequestForm';
import CodeValidationForm from './CodeValidationForm';
import EntryPoint from './EntryPoint';
import SecondaryButton from '../SecondaryButton';
import ThankYou from '../ThankYou';

import { initialState, reducer } from '../../models/get-started';

import { sendEvent } from '../../services/analytics';

const useStyles = makeStyles(theme => ({
	startOverButton: {
		marginLeft: theme.spacing(2),
	},
}));

const StateWrapper = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const classes = useStyles();
	const {
		contactFound,
		contactHasOrdered,
		contactSearchedFor,
		isCodeValid,
		formSubmitted,
	} = state;
	const data = useStaticQuery(graphql`
		query GetPartnersForGetStarted {
			allPartner {
				nodes {
					id
					name
					description
					isFeaturedPartner
					landingPageSlug
					localPhoto {
						childImageSharp {
							fluid(maxWidth: 600) {
								...GatsbyImageSharpFluid_tracedSVG
							}
						}
					}
					localLogo {
						childImageSharp {
							fixed(width: 75) {
								...GatsbyImageSharpFixed_tracedSVG
							}
						}
					}
					MealTypes {
						id
						name
					}
					PartnerTags {
						id
						name
					}
					SporkLocations {
						id
					}
				}
			}
			allServicedState(sort: { fields: name, order: ASC }) {
				nodes {
					id
					abbreviation
					name
				}
			}
		}
	`);
	const StartOverButton = props => (
		<SecondaryButton
			onClick={() => {
				sendEvent({
					action: 'Start Over',
				});
				dispatch({
					type: 'resetState',
					payload: {
						partnerList: data.allPartner.nodes,
						servicedStates: data.allServicedState.nodes,
					},
				});
			}}
			{...props}
		>
			Start Over
		</SecondaryButton>
	);

	useEffect(() => {
		dispatch({
			type: 'setStateValue',
			payload: {
				key: 'partnerList',
				value: data.allPartner.nodes,
			},
		});
		dispatch({
			type: 'setStateValue',
			payload: {
				key: 'servicedStates',
				value: data.allServicedState.nodes,
			},
		});
	}, []);

	return (
		<>
			{!contactSearchedFor && !formSubmitted && (
				<EntryPoint dispatch={dispatch} />
			)}
			{contactSearchedFor &&
				contactFound &&
				contactHasOrdered &&
				!isCodeValid &&
				!formSubmitted && (
					<CodeValidationForm state={state} dispatch={dispatch} />
				)}
			{contactSearchedFor &&
				((contactFound && contactHasOrdered && isCodeValid) ||
					(contactFound && !contactHasOrdered) ||
					!contactFound) &&
				!formSubmitted && (
					<ClientOrderRequestForm state={state} dispatch={dispatch} />
				)}
			{formSubmitted && (
				<ThankYou
					primaryText="Thanks for your request!"
					secondaryText="One of our team members will be in touch with you very soon!"
					secondaryAction={
						<StartOverButton className={classes.startOverButton} />
					}
				/>
			)}
			{contactSearchedFor && !formSubmitted && (
				<div style={{ margin: `16px 0` }}>
					<StartOverButton />
				</div>
			)}
		</>
	);
};

export default StateWrapper;
